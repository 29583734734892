* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* .main {
  width: 100%;
  padding: 20px;
} */

.sidebar {
  background: rgb(15, 133, 169);
  color: rgb(255, 255, 255);
  height: 100vh;
  width: 200px;

  transition: all 0.5s;
  margin: 0 !important;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 5px;
}

.logo {
  font-size: 20px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  color: white;
  padding: 10px 10px;
  gap: 10px;
  transition: all 0.5s;
}

.link:hover {
  background: lightskyblue;
  color: black;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: black;
}

.icon .link_text {
  font-size: 20px;
}

.popover-style {
  width: 300px;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.5rem !important;
}
